import {
    LIST_ORDERS, SAVE_ORDER,
    SET_ORDER,
    SET_ORDERS, UPDATE_ORDER
} from '../actions/orders/types';
import {SAVE_USER} from "../actions/users/types";

const initialState = {
    isFetching: false,
    orders: [],
    order: {},
    lastFilter: {},
};

const orders = (state = initialState, action) => {
    switch (action.type) {
        case LIST_ORDERS:
            return {
                ...state,
                isFetching: action.isFetching || false,
                orders: action.orders || state.orders,
            };
        case SET_ORDERS:
            return {
                ...state,
                isFetching: false,
                orders: action.orders,
                lastFilter: action.filter || {},
            };
        case SET_ORDER:
            return {
                ...state,
                isFetching: false,
                order: action.order,
            };
        case UPDATE_ORDER:
            return {
                ...state,
                order: {
                    ...state.order,
                    [action.field]: action.value,
                },
            };
        case SAVE_ORDER:
            return { ...state, isFetching: action.isFetching || false };
        default:
            return state;
    }
};

export default orders;
