import {
  LIST_BRANDS,
  UPDATE_BRAND,
  SET_BRAND,
  SET_BRANDS,
  SAVE_BRAND,
  REMOVE_BRAND,
  LIST_BRANDSCOLLECTIONS,
  REMOVE_BRANDCOLLECTION,
  UPDATE_BRANDCOLLECTION,
  SET_BRANDCOLLECTION,
  SET_BRANDSCOLLECTIONS,
  SAVE_BRANDCOLLECTION,
} from '../actions/brands/types';

const initialState = {
  isFetching: false,
  brands: [],
  lastFilter: {},
  brandDetail: {},
  brandCollectionDetail: {},
  collections: [],
  lastFilterBrandCollection: {},
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case LIST_BRANDS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        brands: action.brands || state.brands,
      };
    case LIST_BRANDSCOLLECTIONS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        collections: action.collections || state.collections,
      };
    case REMOVE_BRAND:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case REMOVE_BRANDCOLLECTION:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_BRAND:
      return {
        ...state,
        brandDetail: {
          ...state.brandDetail,
          [action.field]: action.value,
        },
      };
    case UPDATE_BRANDCOLLECTION:
      return {
        ...state,
        brandCollectionDetail: {
          ...state.brandCollectionDetail,
          [action.field]: action.value,
        },
      };
    case SET_BRAND:
      return {
        ...state,
        isFetching: false,
        brandDetail: action.brandDetail,
      };
    case SET_BRANDCOLLECTION:
      return {
        ...state,
        isFetching: false,
        brandCollectionDetail: action.brandCollectionDetail,

      };
    case SET_BRANDS:
      return {
        ...state,
        isFetching: false,
        brands: action.brands,
        lastFilter: action.filter || {},
      };
    case SET_BRANDSCOLLECTIONS:
      return {
        ...state,
        isFetching: false,
        collections: action.collections,
        lastFilterBrandCollection: action.filter || {},
      };
    case SAVE_BRAND:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_BRANDCOLLECTION:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default brands;
