export const UPDATE_BRAND = 'UPDATE_BRAND';
export const SET_BRAND = 'SET_BRAND';
export const SET_BRANDS = 'SET_BRANDS';
export const LIST_BRANDS = 'LIST_BRANDS';
export const LIST_BRANDS_FILTER = 'LIST_BRANDS_FILTER';
export const LOAD_BRAND = 'LOAD_BRAND';
export const SAVE_BRAND = 'SAVE_BRAND';
export const REMOVE_BRAND = 'REMOVE_BRAND';
export const LIST_BRANDSCOLLECTIONS = 'LIST_BRANDSCOLLECTIONS';
export const SET_BRANDSCOLLECTIONS = 'SET_BRANDSCOLLECTIONS';
export const UPDATE_BRANDCOLLECTION = 'UPDATE_BRANDCOLLECTION';
export const SET_BRANDCOLLECTION = 'SET_BRANDCOLLECTION';
export const LOAD_BRANDCOLLECTION = 'LOAD_BRANDCOLLECTION';
export const SAVE_BRANDCOLLECTION = 'SAVE_BRANDCOLLECTION';
export const REMOVE_BRANDCOLLECTION = 'REMOVE_BRANDCOLLECTION';
