import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import customers from './customers';
import users from './users';
import content from './content';
import brands from './brands';
import orders from './orders';
import categories from './categories';
import productFeatures from './productFeatures';
import products from './products';
import newsletters from './newsletters';
import posts from './posts';
import reservations from "./reservations";
const appReducer = combineReducers({
  app,
  auth,
  customers,
  users,
  content,
  brands,
  categories,
  productFeatures,
  products,
  newsletters,
  orders,
  reservations,
  posts
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    const { app, layout } = state;
    state = { app, layout };
  }
  return appReducer(state, action);
};

export default rootReducer;
