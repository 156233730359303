import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  LIST_POSTS,
  UPDATE_POST,
  SET_POST,
  SET_POSTS,
  SAVE_POST,
  REMOVE_POST,
  SAVE_POSTCOLLECTION
} from "../actions/posts/types";


const initialState = {
  isFetching: false,
  posts: [],
  postDetail: {},
  postPageTotal: 0,
  lastPage: 0,
  lastFilterPostsCollection: {},
};

const posts = (state = initialState, action) => {
  switch (action.type) {
    case LIST_POSTS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        posts: action.posts || state.posts,
      };
    case REMOVE_POST:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_POST:
      return {
        ...state,
        postDetail: {
          ...state.postDetail,
          [action.field]: action.value,
          shortDescription:
            action.field === "shortDescriptionState"
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.postDetail.shortDescription,
        },
      };
    case SET_POST:
      return {
        ...state,
        isFetching: false,
        postDetail: action.postDetail,
      };
    case SET_POSTS:
      return {
        ...state,
        isFetching: false,
        posts: action.posts,
        lastFilterPostsCollection: action.filter || {},
      };
    case SAVE_POST:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
}
export default posts;