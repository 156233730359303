export const appVersion = "1.0";
export const AppName = "Tensen CMS";
export const mainColor = "#d1ad49";
export const awsAccessKey = "AKIAVDSJI7LFLSUAP5X4";
export const awsSecretKey = "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";
export const bucketName = "tensenuploads";
export const frontEndLanguages = [
  {
    iso: "nl",
    language: "Nederlands",
  },
  {
    iso: "fr",
    language: "Frans",
  },
  {
    iso: "en",
    language: "Engels",
  },
];
export const orderByList = [
  {
    iso: "sort_order",
    text: "Sort order",
  },
  {
    iso: "created",
    text: "Created",
  },
  {
    iso: "price",
    text: "Prijs",
  },
  {
    iso: "name",
    text: "Naam",
  },
];
export const orderTypeList = [
  {
    iso: 1,
    text: "Asc",
  },
  {
    iso: -1,
    text: "Desc",
  },

];