import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTokenUser } from "../../auth/auth";
import { getRouteTitle } from "../../pages/Main/Router";
import "./style.scss";
import { signOutUser } from "../../redux/actions/auth";
import swal from "sweetalert";

class Header extends Component {
  signOutUser = () => {
    swal({
      title: "Volledig afmelden",
      text: "Bent u zeker dat u wil afmelden?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props.doSignoutUser().then(() => {
          this.props.history.push("/signin");
        });
      }
    });
  };
  render() {
    const user = getTokenUser();
    return (
      <header className="header-root">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div>
              <span>{getRouteTitle(this.props.location.pathname)}</span>
            </div>
            <div className="header-root__user">
              <span className="header-root__user__name">{user.fullName}</span>
              <button
                className="btn btn-outline-danger"
                type="submit"
                onClick={() => this.signOutUser()}
              >
                Afmelden
              </button>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doSignoutUser: () => {
      return dispatch(signOutUser());
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Header));
