import {
    LIST_CUSTOMERS,
    REMOVE_CUSTOMER,
    SAVE_CUSTOMER,
    SET_CUSTOMER,
    SET_CUSTOMERS,
    UPDATE_CUSTOMER
} from "../actions/customers/types";
import {SET_NEWSLETTERS} from "../actions/newsletters/types";

const initialState = {
    isFetching: false,
    customers: [],
    customerDetail: {}
};

const customers = (state = initialState, action) => {
    switch (action.type) {
        case LIST_CUSTOMERS:
            return {
                ...state,
                isFetching: action.isFetching || false,
                customers: action.customers || state.customers,
            };
        case SET_CUSTOMERS:
            return {
                ...state,
                isFetching: false,
                customers: action.customers,
            };
        case REMOVE_CUSTOMER:
            return {
                ...state,
                isFetching: action.isFetching || false,
            };
        case SAVE_CUSTOMER:
            return {...state, isFetching: action.isFetching || false};
        case SET_CUSTOMER:
            return {
                ...state,
                isFetching: false,
                customerDetail: action.customerDetail,
            };
        case UPDATE_CUSTOMER:
            return {
                ...state,
                customerDetail: {
                    ...state.customerDetail,
                    [action.field]: action.value,
                },
            };

        default:
            return state;
    }
};

export default customers;
