import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from '../../components/Loader';
import HashedRoute from '../../HashedRoute';

const Customers = lazy(() => import('./Customers'));
const CustomersDetail = lazy(() => import('./CustomersDetail'));
const Users = lazy(() => import('./Users'));
const UsersDetail = lazy(() => import('./UsersDetail'));
const Content = lazy(() => import('./Content'));
const ContentDetail = lazy(() => import('./ContentDetail'));
const Contentgroups = lazy(() => import('./ContentGroups'));
const ContentgroupsDetail = lazy(() => import('./ContentGroupsDetail'));
const Brands = lazy(() => import('./Brands'));
const BrandCollections = lazy(() => import('./BrandCollections'));
const BrandCollectionDetail = lazy(() => import('./BrandCollectionDetail'));
const Products = lazy(() => import('./Products'));
const ProductDetail = lazy(() => import('./ProductDetail'));
const BrandDetail = lazy(() => import('./BrandDetail'));
const ProductFeatures = lazy(() => import('./ProductFeatures'));
const ProductFeatureDetail = lazy(() => import('./ProductFeatureDetail'));
const Importer = lazy(() => import('./Importer'));
const Categories = lazy(() => import('./Categories'));
const CategoryDetail = lazy(() => import('./CategoryDetail'));
const Newsletters = lazy(() => import('./NewsLetters'));
const Orders = lazy(() => import('./Orders'));
const OrderDetail = lazy(() => import('./OrderDetail'));
const Posts = lazy(() => import('./Posts'));
const PostDetail = lazy(() => import('./PostDetail'));
const Reservations =  lazy(() => import('./Reservations'));
const ReservationDetail =  lazy(() => import('./ReservationDetail'));

const routes = [
  {
    path: '/users',
    component: Users,
    title: 'Gebruikers',
  },
  {
    path: '/users/new',
    component: UsersDetail,
    title: 'Nieuwe Gebruiker',
  },
  {
    path: '/users/detail/:id',
    component: UsersDetail,
    title: 'Gebruiker Detail',
  },
  {
    path: '/customers/new',
    component: CustomersDetail,
    title: 'Nieuwe Klant',
  },
  {
    path: '/customers/detail/:id',
    component: CustomersDetail,
    title: 'Klant Detail',
  },
  {
    path: '/customers',
    component: Customers,
    title: 'Klanten',
  },
  {
    path: '/categories',
    component: Categories,
    title: 'Categorieën',
  },
  {
    path: '/categories/new',
    component: CategoryDetail,
    title: 'Nieuwe Categorie',
  },
  {
    path: '/categories/detail/:id',
    component: CategoryDetail,
    title: 'Categorie Detail',
  },
  {
    path: '/products/new',
    component: ProductDetail,
    title: 'Nieuw Product',
  },
  {
    path: '/products/detail/:id',
    component: ProductDetail,
    title: 'Product Detail',
  },
  {
    path: '/productfeatures',
    component: ProductFeatures,
    title: 'Product Features',
  },
  {
    path: '/productfeatures/new',
    component: ProductFeatureDetail,
    title: 'Nieuwe Feature',
  },
  {
    path: '/productfeatures/detail/:id',
    component: ProductFeatureDetail,
    title: 'Feature Detail',
  },
  {
    path: '/brands',
    component: Brands,
    title: 'Merken',
  },
  {
    path: '/brands/new',
    component: BrandDetail,
    title: 'Nieuw Merk',
  },
  {
    path: '/brands/detail/:id',
    component: BrandDetail,
    title: 'Merk Detail',
  },
  {
    path: '/brandcollections',
    component: BrandCollections,
    title: 'Merk Collecties',
  },
  {
    path: '/brandcollections/new',
    component: BrandCollectionDetail,
    title: 'Nieuwe Collectie',
  },
  {
    path: '/brandcollections/detail/:id',
    component: BrandCollectionDetail,
    title: 'Collectie Detail',
  },
  {
    path: '/products',
    component: Products,
    title: 'Producten',
  },
  {
    path: '/content',
    component: Content,
    title: 'Content',
  },
  {
    path: '/content/new',
    component: ContentDetail,
    title: 'Nieuwe Content',
  },
  {
    path: '/content/detail/:id',
    component: ContentDetail,
    title: 'Content Detail',
  },
  {
    path: '/contentgroups',
    component: Contentgroups,
    title: 'Contentgroepen',
  },
  {
    path: '/contentgroups/new',
    component: ContentgroupsDetail,
    title: 'Nieuwe Contentgroep',
  },
  {
    path: '/contentgroups/detail/:id',
    component: ContentgroupsDetail,
    title: 'Contentgroep Detail',
  },
  {
    path: '/import',
    component: Importer,
    title: 'Importeren',
  },
  {
    path: '/newsletter',
    component: Newsletters,
    title: 'Nieuwsbrief',
  },
  {
    path: '/orders',
    component: Orders,
    title: 'Orders',
  },
  {
    path: '/reservations',
    component: Reservations,
    title: 'Reservations',
  },
  {
    path: '/reservations/detail/:id',
    component: ReservationDetail,
    title: 'Reservations Detail',
  },
  {
    path: '/order/detail/:id',
    component: OrderDetail,
    title: 'Order Detail',
  },
  {
    path: '/posts',
    component: Posts,
    title: 'Posts',
  },
  {
    path: '/posts/new',
    component: PostDetail,
    title: 'Create Post',
  },
  {
    path: '/posts/detail/:id',
    component: PostDetail,
    title: 'Post Detail',
  },
];

const Router = (props) => {
  const createRoute = (path, Component, index) => {
    return (
      <Route
        exact
        key={index}
        path={path}
        render={(props) => <Component {...props} />}
      />
    );
  };

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((item, i) => {
          return createRoute(item.path, item.component, i);
        })}
        <HashedRoute path="/" component={Users} />
      </Switch>
    </Suspense>
  );
};

export default Router;

export const getRouteTitle = (path) => {
  for (var i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (
      path.indexOf(route.pathForTitle ? route.pathForTitle : route.path) === 0
    ) {
      return route.title;
    }
  }
  return 'Geen titel';
};
