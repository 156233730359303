import {
  LIST_CONTENTS,
  UPDATE_CONTENT,
  SET_CONTENT,
  SET_CONTENTS,
  SAVE_CONTENT,
  REMOVE_CONTENT,
  LIST_CONTENTGROUPS,
  UPDATE_CONTENTGROUP,
  SET_CONTENTGROUP,
  SET_CONTENTGROUPS,
  SAVE_CONTENTGROUP,
  REMOVE_CONTENTGROUP,
} from "../actions/content/types";

const initialState = {
  isFetching: false,
  contents: [],
  contentDetail: {},
  contentgroups: [],
  contentgroupDetail: {},
};

const contents = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CONTENTS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        contents: action.contents || state.contents,
      };
    case REMOVE_CONTENT:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_CONTENT:
      return {
        ...state,
        contentDetail: {
          ...state.contentDetail,
          [action.field]: action.value,
        },
      };
    case SET_CONTENT:
      return {
        ...state,
        isFetching: false,
        contentDetail: action.contentDetail,
      };
    case SET_CONTENTS:
      return {
        ...state,
        isFetching: false,
        contents: action.contents,
      };
    case SAVE_CONTENT:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_CONTENTGROUPS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        contentgroups: action.contentgroups || state.contentgroups,
      };
    case REMOVE_CONTENTGROUP:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_CONTENTGROUP:
      return {
        ...state,
        contentgroupDetail: {
          ...state.contentgroupDetail,
          [action.field]: action.value,
        },
      };
    case SET_CONTENTGROUP:
      return {
        ...state,
        isFetching: false,
        contentgroupDetail: action.contentgroupDetail,
      };
    case SET_CONTENTGROUPS:
      return {
        ...state,
        isFetching: false,
        contentgroups: action.contentgroups,
      };
    case SAVE_CONTENTGROUP:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default contents;
