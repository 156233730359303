import {
    LIST_RESERVATIONS, SAVE_RESERVATION,
    SET_RESERVATION,
    SET_RESERVATIONS, UPDATE_RESERVATION
} from '../actions/reservations/types';
import {SAVE_USER} from "../actions/users/types";

const initialState = {
    isFetching: false,
    reservations: [],
    reservation: {},
    lastFilter: {}
};

const reservations = (state = initialState, action) => {
    switch (action.type) {
        case LIST_RESERVATIONS:
            return {
                ...state,
                isFetching: action.isFetching || false,
                reservations: action.reservations || state.reservations,
            };
        case SET_RESERVATIONS:
            return {
                ...state,
                isFetching: false,
                reservations: action.reservations,
                lastFilter: action.filter || {},
            };
        case SET_RESERVATION:
            return {
                ...state,
                isFetching: false,
                reservation: action.reservation,
            };
        case UPDATE_RESERVATION:
            return {
                ...state,
                reservation: {
                    ...state.reservation,
                    [action.field]: action.value,
                },
            };
        case SAVE_RESERVATION:
            return { ...state, isFetching: action.isFetching || false };
        default:
            return state;
    }
};

export default reservations;
