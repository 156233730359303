import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  LIST_PRODUCTS,
  UPDATE_PRODUCT,
  SET_PRODUCT,
  SET_PRODUCTS,
  SAVE_PRODUCT,
  REMOVE_PRODUCT,
    HIDE_BUTTON,
} from "../actions/products/types";

const initialState = {
  isFetching: false,
  products: [],
  productDetail: {},
  productPageTotal: 0,
  lastFilter: {},
  lastPage: 0,
  hideButton: false,
  productsByBrand:[]
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PRODUCTS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        products: action.products || state.products,
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          [action.field]: action.value,
          shortDescription:
            action.field === "shortDescriptionState"
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.productDetail.shortDescription,
        },
      };
    case SET_PRODUCT:
      return {
        ...state,
        isFetching: false,
        productDetail: action.productDetail,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        isFetching: false,
        products: action.products,
        productPageTotal: action.productPageTotal || 0,
        lastFilter: action.filter || {},
        lastPage: action.page || 0,
        productsByBrand: action.productsByBrand || [],
      };
    case SAVE_PRODUCT:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default products;
