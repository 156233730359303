export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PARENTS_CATEGORIES = "SET_PARENTS_CATEGORIES";
export const LIST_CATEGORIES = "LIST_CATEGORIES";
export const LIST_PARENTS_CATEGORIES = "LIST_PARENTS_CATEGORIES";
export const LIST_CATEGORIES_FILTER = "LIST_CATEGORIES_FILTER";
export const LOAD_CATEGORY = "LOAD_CATEGORY";
export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
